import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Website Routing
import Website from "./website/pages/User";
//NMMS WEBSITE
import Nmmshome from "./website/pages/Nmms/Nmmshome";
import Nmmsteacher from "./website/pages/Nmms/Nmmsteacher";
// import Whatsappgroup from "./website/pages/Nmms/Whatsappgroup";

//Gyan Sadhana Website
import Gyansadhana from "./website/pages/Gyansadhana/Gyansadhana_home";
import Gyansadhanateacher from "./website/pages/Gyansadhana/Gyansadhanateacher";

//Navoday Website
import Navoday from "./website/pages/Navoday/Navodayhome"
import Navodayteacher from "./website/pages/Navoday/Navodayteacher"

//DTM WEBSITE
import Dtmhome from "./website/pages/Dtm/Dtmhome";
import DtmTeam from "./website/pages/Dtm/DtmTeam";
//BLOG WEBSITE
import Blog from "./website/pages/Blog/Bloghome";
import Blogcontent from "./website/pages/Blog/Blog-content";
import Blogcategory from "./website/pages/Blog/Blog-category";



// ----!--------!!-----------!-----------!--------!-------!------!----------
// Admin Panel Routing
import Dashboard from "./admin/pages/common/Dashboard";
import Login from "./admin/pages/Login/Login";

//NMMS Admin
import Nmms_admin from "./admin/pages/nmms_admin/Nmms";
import CreateTeacherProfile from "./admin/pages/nmms_admin/CreateTeacherProfile";
import EditTeacherProfile from "./admin/pages/nmms_admin/EditTeacherProfile";
import ShowQuiz from "./admin/pages/nmms_admin/ShowQuiz";
import EditQuiz from "./admin/pages/nmms_admin/EditQuiz";

//Navoday Admin
import NavodayAdmin from "./admin/pages/Navoday/NavodayAdmin";
import NavodayCreateTeacherProfile from "./admin/pages/Navoday/CreateTeacherProfile";
import NavodayEditTeacherProfile from "./admin/pages/Navoday/EditTeacherProfile";
import NavodayShowQuiz from "./admin/pages/Navoday/ShowQuiz";
import NavodayEditQuiz from "./admin/pages/Navoday/EditQuiz";

//Navoday Admin
import GyanSadhana from "./admin/pages/GyanSadhana/GyanSadhana";
import GyanSadhanaCreateTeacherProfile from "./admin/pages/GyanSadhana/CreateTeacherProfile";
import GyanSadhanaEditTeacherProfile from "./admin/pages/GyanSadhana/EditTeacherProfile";
import GyanSadhanaShowQuiz from "./admin/pages/GyanSadhana/ShowQuiz";
import GyanSadhanaEditQuiz from "./admin/pages/GyanSadhana/EditQuiz";

//DTM
import DtmAdmin from "./admin/pages/DTM/DtmAdmin";
import CreateTeam from "./admin/pages/DTM/CreateTeam";
import TeamInfo from "./admin/pages/DTM/TeamInfo";
import AddContent from "./admin/pages/DTM/AddContent";
import EditTeam from "./admin/pages/DTM/Edit/EditTeam";
import EditContent from "./admin/pages/DTM/Edit/EditContent";
import EditTeacher from "./admin/pages/DTM/Edit/EditTeacher";
import DtmBanner from "./admin/pages/DTM/DtmBanner";
//Blog
import BlogMain from "./admin/pages/Blog/BlogMain";
import CreateBlog from "./admin/pages/Blog/CreateBlog";
import EditBlog from "./admin/pages/Blog/EditBlog";
import CreateCategory from "./admin/pages/Blog/CreateCategory"

//ERROR OR NOT FOUND PAGE
import Page_error from "./admin/pages/common/Page_error";


// Admin CSS & JS
import "./admin/assets/css/style.css";

// User CSS & JS
import "./website/assets/css/style.css";
import "./website/assets/css/footer.css";

//Test
import TestFile from "./admin/pages/nmms_admin/TestFile";
import PopUp from "./website/components/PopUp";

// Web App
import ChaptersMainPage from "./admin/pages/Chapters/ChaptersMainPage";
import TopicListMain from "./admin/pages/Chapters/TopicListMain";
import EduMitraPage from "./admin/pages/Chapters/EduMitraPage";
import EduMitraContent from "./admin/pages/Chapters/EduMitraContent";
import UpdateEduMitraPage from "./admin/pages/Chapters/Edit/UpdateEduMitraPage";
import UpdateChapter from "./admin/pages/Chapters/Edit/UpdateChapter";
import AddSchool from "./admin/pages/Chapters/school/AddSchool";
import UserSignUpList from "./admin/pages/Analytics/UserSignUpList";
import SpecialExam from "./admin/pages/SpecialExam/SpecialExam";


function Allroutes() {
  return (
    <>
      <Router>
        {/* Website Routing */}
        <Routes>
          <Route path="/" element={<Website />} />

          <Route path="/upload-chapters" element={<ChaptersMainPage />} />
          <Route path="/upload-topic" element={<TopicListMain />} />
          <Route path="/upload-edumitra" element={<EduMitraPage />} />
          <Route path="/upload-edumitra-content" element={<EduMitraContent />} />
          <Route path="/edit-edumitra/:id" element={<UpdateEduMitraPage />} />
          <Route path="/edit-chapter/:chapter_id/:subject_id" element={<UpdateChapter />} />
          <Route path="/add-school" element={<AddSchool />} />
          <Route path="/admin/user-data" element={<UserSignUpList />} />
          <Route path="/mock-tests" element={<SpecialExam />} />
          
          {/* NMMS WEBSITE */}
          <Route path="/nmms-exam-mission" element={<Nmmshome />} />
          <Route path="/nmms/teacher/:id" element={<Nmmsteacher />} />
          {/* <Route path="/nmms-whatsapp-groups" element={<Whatsappgroup />} /> */}

          {/* DTM WEBSITE */}
          <Route path="/digital-teacher-mission" element={<Dtmhome />} />
          <Route path="/dtm/my-team/:id" element={<DtmTeam />} />

          {/* BLog Website */}
          <Route path="/blog-home" element={<Blog />} />
          <Route path="/blog/post/:id" element={<Blogcontent />} />
          <Route path="/blogcategory/:id" element={<Blogcategory />} />

          {/* gyansadhana Website */}
          <Route path="/gyan-sadhana-exam-mission" element={<Gyansadhana />} />
          <Route path="/gyansadhana/teacher/:id" element={<Gyansadhanateacher />} />

          {/* navoday Website */}
          <Route path="/navodaya-exam-mission" element={<Navoday />} />
          <Route path="/navodaya/teacher/:id" element={<Navodayteacher />} />

          {/* -------------!---------!---------!------!---!----!---!----!----!------ */}
          {/*Admin Routing */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin/login" element={<Login />} />

          {/* NMMS */}
          <Route path="/nmms-admin" element={<Nmms_admin />} />
          <Route path="/nmms/create-tecacher-profile" element={<CreateTeacherProfile />} />
          <Route
            path="/nmms/edit-tecacher-profile/:id"
            element={<EditTeacherProfile />}
          />
          <Route path="/nmms/showquiz/:id" element={<ShowQuiz />} />
          <Route path="/nmms/editquiz/:id/:obid" element={<EditQuiz />} />

          {/* Gyan Sadhana */}
          <Route path="/gyansadhana-admin" element={<GyanSadhana />} />
          <Route path="/gyansadhana/create-tecacher-profile" element={<GyanSadhanaCreateTeacherProfile />} />
          <Route
            path="/gyansadhana/edit-tecacher-profile/:id"
            element={<GyanSadhanaEditTeacherProfile />}
          />
          <Route path="/gyansadhana/showquiz/:id" element={<GyanSadhanaShowQuiz />} />
          <Route path="/gyansadhana/editquiz/:id/:obid" element={<GyanSadhanaEditQuiz />} />

          {/* Navoday */}
          <Route path="/navoday-admin" element={<NavodayAdmin />} />
          <Route path="/navoday/create-tecacher-profile" element={<NavodayCreateTeacherProfile />} />
          <Route
            path="/navoday/edit-tecacher-profile/:id"
            element={<NavodayEditTeacherProfile />}
          />
          <Route path="/navoday/showquiz/:id" element={<NavodayShowQuiz />} />
          <Route path="/navoday/editquiz/:id/:obid" element={<NavodayEditQuiz />} />

          {/* DTM Routing */}DtmBanner
          <Route path="/dtm-admin" element={<DtmAdmin />} />
          <Route path="/dtm/create-team-profile" element={<CreateTeam />} />
          <Route path="/dtm/edit-team-profile/:id" element={<EditTeam />} />
          <Route path="/dtm/make-banner/:id" element={<DtmBanner />} />

          <Route path="/dtm/team-info/:id" element={<TeamInfo />} />
          <Route path="/dtm/edit-teacher/:id/:obid" element={<EditTeacher />} />
          <Route path="/dtm/add-content/:id" element={<AddContent />} />
          <Route path="/dtm/edit-content/:id/:obid" element={<EditContent />} />

          {/* Blog */}
          <Route path="/blog-main" element={<BlogMain />} />
          <Route path="/blog/create-new-blog" element={<CreateBlog />} />
          <Route path="/blog/edit-blog/:id" element={<EditBlog />} />
          <Route path="/blog/create-createcategory" element={<CreateCategory />} />

          {/* default page */}
          <Route path="*" element={<Page_error />} />

          {/* Testing */}
          <Route path="/test" element={<TestFile />} />
          <Route path="/popup/:id" element={<PopUp />} />
        </Routes>
      </Router>
    </>
  );
}

export default Allroutes;
