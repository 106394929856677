import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const UpdateChapter = () => {
  const navigate = useNavigate();
  document.title = "Edit Chapter | Edutor App";
  const { chapter_id, subject_id } = useParams();
  const [ExamID, setExamID] = useState(null);
  const [Isvisible, setIsvisible] = useState(null);
  const [Context, setContext] = useState(null);

  const UpdateChapter = async () => {
    if (!Isvisible || !chapter_id || !subject_id) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }

    const regex = /content_id\/([temv])=(.*?)$/;
    const match = Context.match(regex);
    const base64String = match[2];
    const decodedString = atob(base64String);
    const id = decodedString;
    try {
      const UpdateChapter = {
        subject_id: parseInt(subject_id),
        chapter_id: parseInt(chapter_id),
        is_visible: JSON.parse(Isvisible),
        special_exam_id: parseInt(ExamID),
        context: id,
      };

      const editTopicResponse = await axios.put(
        `${REACT_APP_NODE_API_BASE_URL}/admin/edit/chapter`,
        UpdateChapter,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (editTopicResponse.data) {
        toast.success(editTopicResponse.data.message, {
          position: "top-center",
        });
        // navigate("/upload-chapters");
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-center",
      });
      console.error("Error updated chapter:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Chapter</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Edit Chapter</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Visible: </label>

                  <select
                    className="form-select"
                    value={Isvisible}
                    onChange={(e) => setIsvisible(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="true">Edu Mitra અને Special Exam બંને - Visible</option>
                    <option value="false">માત્ર Special Exam અથવા સંતાડીડો બધે થી - Hidden</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Context: </label>
                  <input
                    type="text"
                    className="form-control"
                    value={Context}
                    onChange={(e) => setContext(e.target.value)}
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Select Exam Type
                  </label>
                  <select
                    className="form-select"
                    value={ExamID}
                    onChange={(e) => setExamID(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="1">NMMS</option>
                    <option value="2">Gyan Sadhana</option>
                    <option value="3">PSE</option>
                    <option value="4">CET</option>
                    <option value="5">Navoday</option>
                    <option value="6">NEET</option>
                    <option value="7">JEE</option>
                    <option value="8">ધોરણ ૧૦ પરીક્ષા</option>
                  </select>
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-info"
                    name="insert"
                    onClick={UpdateChapter}
                  >
                    Update Chapter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateChapter;
